import styled, { css } from 'styled-components';
import {
    mediaQuery,
    setColors,
    setFontSize,
    setFontWeight,
} from '../../../utils/helpers';

const headerStyle = css`
	${({ theme }) => `${theme.marginAuto}; ${theme.textCenter}`};
	color: ${setColors.lightBlack};
	font-weight: ${setFontWeight.extraBold} !important;
	line-height: 1.11 !important;
	max-width: 800px;
	letter-spacing: -1.5px !important;
	margin-bottom: 30px;
	padding: 70px 15px 0 15px;
	${mediaQuery.mobileXL`padding-top: 35px`}
`;

export const Title = styled.h1`
	${headerStyle}
`;

export const SectionTitle = styled.h2`
	${headerStyle}
`;

export const CTAWrapper = styled.div`
	padding-top: 120px;
	${mediaQuery.mobileXL`padding-top: 54px;padding-bottom: 20px;`};
`;

export const SubSection = styled.div`
	max-width: 900px;
	padding: 0 15px;
	${({ theme }) => `${theme.marginAuto}; ${theme.textCenter}`};
	p {
		margin: 0 0 0.85em;
		line-height: 1.3 !important;
		font-weight: ${setFontWeight.normal} !important;
		font-size: ${setFontSize.verySmall};
		${mediaQuery.laptopXL`font-size : ${setFontSize.mediumTiny}`};
	}
	div {
		padding: 30px 0;
	}
	${mediaQuery.mobileXL`margin-bottom : 20px`}
`;

export const NoMatchWrapper = styled.div`
	margin-top: 60px;
	text-align: center;
	animation: fade-in 1s ease;
	transition: all 0.3s fade;
	@keyframes fade-in {
		from {
			transform: scale(0.9);
			opacity: 0;
		}
		to {
			transform: scale(1);
			opacity: 1;
		}
	}
	img{
		width: 100%;
		max-width: 350px;
		height: auto;
	}
	.title{
		margin-top: 5px;
		${mediaQuery.mobileXS`
			font-size: ${setFontSize.small}
		`}
	}
	.subtitle{
		margin-top: 10px;
	}
`
