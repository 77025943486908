import React from 'react';
import * as S from '../styles';
import SpaceShuttle from '../../../images/spaceshuttle.png';

const NoMatch = () => {
	return (
		<>
			<S.NoMatchWrapper>
				<img alt="Space Shuttle" src={SpaceShuttle}></img>
				<div className="font-bold-34">
					Oops! No matches found{' '}
					<span role="img" aria-labelledby="smiley">
						🙂
					</span>
				</div>
				<div className="font-black-16 subtitle">
					Please try different keywords
				</div>
			</S.NoMatchWrapper>
		</>
	);
};

export default NoMatch;
