export const trackGAEvents = (category, action, label, value) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'eventTracking',
		eventTacking_category: category,
		eventTacking_action: action,
		eventTacking_label: label,
		eventTacking_value: value,
	});
};
