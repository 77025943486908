import React, { useCallback, useEffect, useRef, useState } from 'react';
import { navigate } from '@reach/router';
import debounce from 'lodash.debounce';
import { SearchBox } from './searchbox/searchbox';
import { FilterDropdown } from './dropdown/dropdown';
import { trackGAEvents } from '../../../helpers/trackGAEvents';
import { USECASE_TAGS_TYPE } from '../../../../utils/constants';
import * as S from './style';

const getUniqueTags = tagsList => {
	const result = tagsList.reduce((acc, tags, i) => {
		const keys = Object.keys(tags);
		keys.forEach(key => {
			if (!(key in acc)) {
				acc[key] = [];
			}
			acc[key].push(
				...(tags[key]?.map(tag => tag).filter(tag => !acc[key].includes(tag)) ||
					[]),
			);
		});
		return acc;
	}, {});
	return result;
};

const FilterMenu = ({ data, setFilterData, slug }) => {
	const { industries, stages } = getUniqueTags(
		data.map(usecase => usecase.tags),
	);
	const [filterForIndustries, setFilterForIndustries] = useState(
		new Array(industries.length).fill(''),
	);
	const [filterForStages, setFilterForStages] = useState(
		new Array(stages.length).fill(''),
	);
	const [searchBy, setSearchBy] = useState('');
	const searchInputHelperRef = useRef('');

	const getSelectedTagsBy = (optionState, optionTags) => {
		return optionState.reduce((acc, option, i) => {
			if (option) acc.push(optionTags[i].toLowerCase());
			return acc;
		}, []);
	};

	const searchHandler = e => {
		debounce_Search(e.target.value.trim());
	};
	const debounce_Search = useCallback(
		debounce(value => {
			setSearchBy(value);
		}, 300),
		[],
	);

	const dropdownHandler = (position, state, setState, key, options) => {
		const updatedCheckedState = state.map((item, i) =>
			i === position ? !item : '',
		);
		setState(updatedCheckedState);
	};

	const filterBySearch = filteredData => {
		return filteredData.filter(({ tags, section1Title }) => {
			if (searchBy === '') return true;
			return (
				section1Title.toLowerCase().includes(searchBy) ||
				[...tags.industries, ...tags.stages]
					.map(tag => tag.toLowerCase())
					.includes(searchBy.toLowerCase())
			);
		});
	};

	const filterByOptions = (optionState, optionTags, filteredData, tagsKey) => {
		return filteredData.filter(({ tags }) => {
			if (optionState.every(option => !option)) return true;
			return getSelectedTagsBy(optionState, optionTags).some(tag =>
				tags[tagsKey.toLowerCase()]
					.map(d => d.toLowerCase())
					.includes(tag.toLowerCase()),
			);
		});
	};

	const clearAllFilter = () => {
		navigate(`/${slug}/`);
		if (
			!(
				searchBy === '' &&
				filterForIndustries.every(item => item === '') &&
				filterForStages.every(item => item === '')
			)
		) {
			setSearchBy('');
			searchInputHelperRef.current.value = '';
			setFilterForIndustries(new Array(industries.length).fill(''));
			setFilterForStages(new Array(stages.length).fill(''));
		}
	};

	const getTrackData = () => {
		return {
			search: searchBy,
			filterBy_Industry: filterForIndustries.reduce((acc, val, i) => {
				if (val) return industries[i];
				return acc;
			}, ''),
			filterBy_Stage: filterForStages.reduce((acc, val, i) => {
				if (val) return stages[i];
				return acc;
			}, ''),
		};
	};

	useEffect(() => {
		let filteredData = data;
		filteredData = filterBySearch(filteredData);
		filteredData = filterByOptions(
			filterForIndustries,
			industries,
			filteredData,
			USECASE_TAGS_TYPE.INDUSTRIES,
		);
		filteredData = filterByOptions(
			filterForStages,
			stages,
			filteredData,
			USECASE_TAGS_TYPE.STAGES,
		);
		if (filteredData !== data) {
			setFilterData(filteredData);
		}
		trackGAEvents('', '', 'useCases - Filter', JSON.stringify(getTrackData()));
		// eslint-disable-next-line
	}, [filterForStages, filterForIndustries, searchBy]);
	return (
		<>
			<S.filterWrapper>
				<div>
					<SearchBox
						searchBy={searchBy}
						setSearchByHandler={searchHandler}
						searchInputHelperRef={searchInputHelperRef}
					></SearchBox>
				</div>
				<div>
					<FilterDropdown
						title={'Filter By Industry'}
						options={industries}
						state={filterForIndustries}
						setState={setFilterForIndustries}
						handleOnChange={dropdownHandler}
						queryStringKey={'filterby_industries'}
					/>
				</div>
				<div>
					<FilterDropdown
						title={'Filter By Use Case'}
						options={stages}
						state={filterForStages}
						setState={setFilterForStages}
						handleOnChange={dropdownHandler}
						queryStringKey={'filterby_stages'}
					/>
				</div>
				<button onClick={clearAllFilter}>Clear</button>
			</S.filterWrapper>
		</>
	);
};

export default FilterMenu;
