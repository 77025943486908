import React, { useState } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/global/seo';
import Layout from '../components/global/layout/Layout';
import CustomerHolder from '../components/customers-page/customer-holder/CustomerHolder';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import FilterMenu from '../components/usecase-homepage/filter-menu/filter-menu';
import NoMatch from '../components/usecase-homepage/nomatch/nomatch';
import { CTAWrapper } from '../components/demand-generation/styles';
import { Container } from '../components/global/customers/styles';
import * as S from '../components/usecase-homepage/styles';

const UsecaseHomepage = ({ data }) => {
	const usecases = data.contentfulUseCaseHomepage.usecases;
	const {
		slug,
		heroTitle,
		heroSubtitle,
		cta,
		seo,
	} = data.contentfulUseCaseHomepage;

	const dataToFilter = usecases;
	const [filterData, setFilterData] = useState(dataToFilter);
	return (
		<>
			<Layout>
				<SEO {...seo} />
				<Container>
					<S.Title className="font-black-54">{heroTitle}</S.Title>
					<S.SubSection>
						<p>{heroSubtitle}</p>
					</S.SubSection>
				</Container>
				<WrapperOne>
					<FilterMenu
						data={dataToFilter}
						setFilterData={setFilterData}
						slug={slug}
					/>
					{filterData.length > 0 ? (
						<CustomerHolder customers={filterData} isUsecase />
					) : (
						<NoMatch />
					)}
				</WrapperOne>
				<S.CTAWrapper>
					<Container>
						<CTAWrapper>
							<MasterCTA cta={cta} />
						</CTAWrapper>
					</Container>
				</S.CTAWrapper>
			</Layout>
		</>
	);
};

export const pageQuery = graphql`
	{
		contentfulUseCaseHomepage {
			slug
			heroTitle
			heroSubtitle
			cta {
				...cta
			}
			seo {
				...seo
			}
			usecases {
				slug
				tags {
					industries
					stages
				}
				homepageImage {
					...asset
				}
				section1Title
				popular
			}
		}
	}
`;

export default UsecaseHomepage;
