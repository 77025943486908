import styled from 'styled-components';
import {
    setColors,
    setFontSize,
    mediaQuery,
} from '../../../../utils/helpers';

export const filterWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 70px 20px 0px;
    font-size: ${setFontSize.mediumTiny};
    max-width: 821px !important;
    margin: 5px auto;
    >div{
        padding: 10px;
        flex-grow: 1;
    }
    button{
        color: ${setColors.planCardGrey};
        background: none;
        border:none;
        padding: 10px 5px;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 5px;
        cursor: pointer;
        letter-spacing: 1px;
        font-size: ${setFontSize.mediumTiny};
        margin-left: auto;
        width: auto;
        &:hover{
            opacity: 0.85;
        }
        ${mediaQuery.tabletM`
            width: -webkit-fill-available;
            text-align: end;
        `}
    }
    ${mediaQuery.tabletM`
        padding: 70px 16% 0px; 
        >div:nth-child(1){
           width: 100%;
        }   
    `}
    ${mediaQuery.mobileXL`
        padding: 70px 0% 0px;
    `}
    ${mediaQuery.mobileXXS`
        >div{
            padding-right:0px;
            padding-left:0px
        }
    `}
`
export const searchBox = styled.div`
    position: relative;
    input{
        color: ${setColors.lightBlack};
        background: ${setColors.white};
        padding: 10px 15px 10px 30px;
        border: 1px solid ${setColors.themeBlue};
        border-radius: 5px;
        font-size: ${setFontSize.mediumTiny};
        width: -webkit-fill-available;
        ${mediaQuery.tabletM`
            padding-right:0px;
        `}
    }
    img{
        position: absolute;
        top: 8px;
        left: 6px;
        width: 20px;
    }
`

export const filterDropdown = styled.div`
    color: ${setColors.themeBlue};
    background: ${setColors.white};
    position: relative;
    min-width: 200px;
    .title {
        display: inline-block;
        cursor: pointer;
        background: ${setColors.white};
        color: ${setColors.lightBlack};
        border: 1px solid ${setColors.themeBlue};
        border-radius: 5px;
        padding: 10px 19px 10px 15px;
        width: 85%;
        &::after {
            display: inline-block;
            content: '>';
            padding-top: 6px;
            float: right;
            font-size: ${setFontSize.minute} !important;
            transform: rotate(90deg) scale(1,2);
            position: absolute;
            right: 4px;
            ${mediaQuery.mobileS`
                right: 8px;
            `}
        }
        &:hover{
            background: ${setColors.gray};
        }
        &:hover+.select-options {
            display: block;
        }
        ${mediaQuery.tabletM`
            width: -webkit-fill-available;
        `}
    }

    .select-options {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 3;
        border: 1px solid ${setColors.gray};
        border-radius: 5px;
        background: ${setColors.white};
        max-height: 280px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 10%), 0px -4px 4px rgb(0 0 0 / 10%);
        overflow-y: auto;
        overflow-x: auto;
        &:hover {
            display: block;
        }
        .option {
            position: relative;
            label {
                color: ${setColors.lightBlack};
                font-size: ${setFontSize.littleTiny};
                cursor: pointer;
                display: flex;
                padding: 10px 30px 10px 10px;
                white-space: nowrap;
                width:  -webkit-fill-available;
                max-width: 80% !important;
                span{
                    white-space: nowrap;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
            &:hover {
                background: ${setColors.gray};
            }
            input {
                display: none;
                cursor: pointer;
                margin: 10px 0 12px 15px;
            }
            label:before{
                content:'';
                -webkit-appearance: none;
                background-color: transparent;
                padding: 10px;
                display: inline-block;
                vertical-align: middle;
                cursor: pointer;
                margin-right: 5px;
            }
            input:checked + label:after{
                content: '';
                display: block;
                position: absolute;
                top: 11px;
                left: 18px;
                width: 5px;
                height: 11px;
                border: solid ${setColors.themeBlue};
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }
    ${mediaQuery.tabletM`
        width: -webkit-fill-available;
    `}
    ${mediaQuery.laptopXS`
        min-width:150px;
    `}
`