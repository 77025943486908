import React from 'react';
import * as S from '../style';
import SearchIcon from '../../../../images/search.png';

export const SearchBox = ({ setSearchByHandler, searchInputHelperRef }) => {
	return (
		<>
			<S.searchBox>
				<img src={SearchIcon} alt="search-icon" />
				<input
					type="text"
					placeholder="Search"
					onChange={setSearchByHandler}
					ref={searchInputHelperRef}
				/>
			</S.searchBox>
		</>
	);
};
