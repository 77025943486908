import React from 'react';
import * as S from '../style';

export const FilterDropdown = ({
	title,
	options,
	state,
	setState,
	handleOnChange,
	queryStringKey,
}) => {
	const getTitle = () => {
		return state.reduce((acc, option, i) => {
			if (option === true) {
				acc = options[i];
			}
			return acc;
		}, title);
	};

	return (
		<>
			<S.filterDropdown>
				<div className="title">{getTitle()}</div>
				<div className="select-options">
					{options.map((option, i) => (
						<div className="option" id={i} title={option}>
							<input
								type="checkbox"
								name={title}
								id={`custom${i}-${option.split(' ').join('')}`}
								value={option}
								checked={state[i]}
								onChange={() =>
									handleOnChange(i, state, setState, queryStringKey, options)
								}
							/>
							<label htmlFor={`custom${i}-${option.split(' ').join('')}`}>
								<span>{option}</span> 	
							</label>
						</div>
					))}
				</div>
			</S.filterDropdown>
		</>
	);
};
